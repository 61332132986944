const API_URL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3000';
const LEGACY_MY_PROJECTS_URL_LOWES = process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_LOWES;
const LEGACY_MY_PROJECTS_URL_SAMS = process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_SAMS;
const LEGACY_MY_PROJECTS_URL_FLOORANDDECOR =
  process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_FLOORANDDECOR;
const LEGACY_MY_PROJECTS_URL_RONA = process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_RONA;
const LEGACY_MY_PROJECTS_URL_BJS = process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_BJS;
const LEGACY_MY_PROJECTS_URL_TRUEVALUE = process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_TRUEVALUE;
const LEGACY_MY_PROJECTS_URL_RENODEPOT = process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_RENODEPOT;
const LEGACY_MY_PROJECTS_URL_WAYFAIR = process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_WAYFAIR;
const LEGACY_MY_PROJECTS_URL_CALIBAMBOO = process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_CALIBAMBOO;
const LEGACY_MY_PROJECTS_URL_FLOORTRADER =
  process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_FLOORTRADER;
const LEGACY_MY_PROJECTS_URL_ACEHARDWARE =
  process.env.NEXT_PUBLIC_LEGACY_MY_PROJECTS_URL_ACEHARDWARE;
const ANALYTICS_ENABLED = process.env.NEXT_PUBLIC_ANALYTICS_ENABLED || false;
const ANALYTICS_KEY = process.env.NEXT_PUBLIC_ANALYTICS_KEY || '';
const HELLO_SIGN_CLIENT_ID = process.env.NEXT_PUBLIC_HELLO_SIGN_CLIENT_ID;
const HELLO_SIGN_SKIP_DOMAIN_VERIFICATION = true; //HelloSign ignores this value for production Client IDs
const SYNCHRONY_APPLY_DOMAIN = process.env.NEXT_PUBLIC_SYNCHRONY_APPLY_DOMAIN;

const envs = ['development', 'staging', 'uat', 'production'];
export const getEnvironment = (env?: string) => envs.find((e) => e === env) || 'local';

enum SizeEnum {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

enum ColorTypeEnum {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
  DEFAULT = 'default',
  SURFACE_GREEN = 'surfaceGreen',
  SURFACE_BLUE = 'surfaceBlue',
  PAYPAL = 'paypal',
}

enum AlertTypeEnum {
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

enum ToastTypeEnum {
  SUCCESS = 'success',
  DANGER = 'danger',
}

enum TextDecorationEnum {
  underline = 'text-decoration-underline',
  linethrough = 'text-decoration-line-through',
  none = 'text-decoration-none',
}

const sessionAge = 30 * 24 * 60 * 60; // 30 days

enum LangEnum {
  EN_US = 'en-US',
  FR_CA = 'fr-CA',
}

const additionalLanguages = JSON.parse(process.env.NEXT_PUBLIC_ADDITIONAL_LANGUAGES || '[]');
export const availableLanguages = [LangEnum.EN_US, ...additionalLanguages];
export const defaultLanguage = LangEnum.EN_US;
export const localePrefix = 'as-needed'; // Default - hides 'en' prefix for default language

const QueryKey = {
  CUSTOMER_PROJECTS: 'customer-projects',
  DOCUMENTS: 'documents',
  DOCUMENT_LINKS: 'document-links',
  PROJECT_STATUS: 'project-status',
  ESTIMATES: 'estimates',
  ESTIMATE_PAYMENT: 'estimate-payment',
  WORKORDER_PAYMENT: 'workorder-payment',
  ACCOUNT_INFO: 'account-info',
  NEXT_ACTIONS: 'next-actions',
  WORK_ORDER: 'work-order',
  PAYMENT_HISTORY: 'payment-history',
  PAYMENT_CONFIRMATION: 'payment-confirmation',
  FINANCE_PROGRAMS: 'finance-programs',
  WORK_ORDER_ALERTS: 'work-order-alerts',
  GET_REQUIRED_DOCUMENTS: 'get-required-documents',
  WORK_ORDER_LIVE_CHAT: 'work-order-live-chat',
  WORK_ORDER_CREATE_SURVEY: 'work-order-create-survey',
  FEATURES: 'features',
} as const;

enum DocumentTypeEnum {
  LEAD_SAFE = 1,
  RIGHT_TO_CANCEL = 2,
  SALE_CONTRACT = 3,
  CUSTOMER_PLEDGE = 4,
  SHOPPING_LIST = 5,
  TIPS_FOR_YOUR_INSTALLATION = 6,
  PREPARING_FOR_THE_MEASURE_APPOINTMENT = 7
}

const enum FormatDateEnum {
  DATE = 'MM/dd/yy',
  FULL_DATE = 'MM/dd/yyyy',
  DATE_TIME = 'MM/dd/yyyy h a',
  TIME = 'hh:mm a',
  YYYY_MM_DD = 'yyyy-MM-dd',
  HH_MM = 'HH:mm',
  H_MM_A = 'h:mm a',
  FULL_DATE_TIME = 'MM/dd/yyyy hh:mm:ss a',
}

const enum WorkOrderStatusEnum {
  APPOINTMENT_RUN = 'APPOINTMENT_RUN',
  SALE_PENDING = 'SALE_PENDING',
  COMPLETED = 'COMPLETED',
  SALE = 'SALE',
  INSTALL_PENDING = 'INSTALL_PENDING',
  INSTALL_SCHEDULED = 'INSTALL_SCHEDULED',
}

enum BusinessCategory {
  Default = 'Default',
  Countertops = 'Countertops',
  Sheds = 'Sheds',
  Bathrooms = 'Bathrooms',
  BathAccessibility = 'Bath Accessibility',
  HVAC = 'HVAC',
  WaterHeaters = 'Water Heaters',
  Gutters = 'Gutters',
  Flooring = 'Flooring',
  HomeOrganization = 'Home Organization',
  Insulation = 'Insulation',
  ArtificialTurf = 'Artificial Turf',
  WaterTreatment = 'Water Treatment',
  Roofing = 'Roofing',
  Siding = 'Siding',
  Assembly = 'Assembly', // Playsets
  Paint = 'Paint',
  GarageDoorOpener = 'Garage Door/Opener',
  XLSlabs = 'XL Slabs',
  Shutters = 'Shutters',
  Generators = 'Generators',
  Cabinets = 'Cabinets',
  EVCharging = 'EV Charging',
  Boiler = 'Boiler',
  ShowerDoor = 'Shower Door',
  LivableHome = 'Livable Home',
}

enum RequiredDocumentsGroup {
  ChangeOrder = 0,
  Sale = 30,
  Completion = 36,
}

enum RetailerEnum {
  BJs = 23,
  FND = 59,
  Lowes = 70,
  AceHardware = 72,
  TrueValue = 73,
  Sams = 82,
  RepoDepot = 85,
  Rona = 86,
  FloorTrader = 118,
}

const enum FinancingParterEnum {
  Other = 1,
  Aqua = 2,
}

const enum WorkOrderAlert {
  NoAlerts,
  SignSaleContract,
  SignCompletionCertificate,
  AcceptChangeOrder,
  SignCompletionCertificateNotComplete,
  CurrentStatusNotHandled = 5,
}

export enum PaymentModalType {
  Default = 0,
  ChangeOrder = 1,
  FinalPayment = 2,
}

const RegExEnum = {
  EMAIL: new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/,
  ),
  SPACE: new RegExp(/^\s+$/),
} as const;

const enum DocumentEnum {
  SALE_CONTRACT = 'SALE_CONTRACT',
  CHANGE_ORDER = 'CHANGE_ORDER',
  COMPLETION_CERTIFICATE = 'COMPLETION_CERTIFICATE',
  NOTICE_OF_RESCISSION = 'NOTICE_OF_RESCISSION',
  OTHERS = 'OTHERS',
  UNIFIED_DOCUMENT = 'UNIFIED_DOCUMENT',
  PERMIT = 'PERMIT',
  BLANK_ESTIMATE_FORM = 'BLANK_ESTIMATE_FORM',
  EXECUTED_RESCISSION = 'EXECUTED_RESCISSION',
}

// From MyProjectsAPI
const enum DepositSetting {
  // Zero deposit up front is required
  ZeroDeposit = 0,

  // Some deposit is required, but not the full project cost
  PartialDeposit = 1,

  // The entirety of the project cost is required as a deposit
  OneHundredPercentDeposit = 2,
}

export {
  API_URL,
  ANALYTICS_ENABLED,
  ANALYTICS_KEY,
  SizeEnum,
  ColorTypeEnum,
  ToastTypeEnum,
  AlertTypeEnum,
  LangEnum,
  sessionAge,
  QueryKey,
  DocumentTypeEnum,
  FormatDateEnum,
  WorkOrderStatusEnum,
  BusinessCategory,
  WorkOrderAlert,
  RequiredDocumentsGroup,
  TextDecorationEnum,
  RetailerEnum,
  HELLO_SIGN_CLIENT_ID,
  HELLO_SIGN_SKIP_DOMAIN_VERIFICATION,
  RegExEnum,
  LEGACY_MY_PROJECTS_URL_LOWES,
  LEGACY_MY_PROJECTS_URL_SAMS,
  LEGACY_MY_PROJECTS_URL_FLOORANDDECOR,
  LEGACY_MY_PROJECTS_URL_RONA,
  LEGACY_MY_PROJECTS_URL_BJS,
  LEGACY_MY_PROJECTS_URL_TRUEVALUE,
  LEGACY_MY_PROJECTS_URL_RENODEPOT,
  LEGACY_MY_PROJECTS_URL_WAYFAIR,
  LEGACY_MY_PROJECTS_URL_CALIBAMBOO,
  LEGACY_MY_PROJECTS_URL_FLOORTRADER,
  LEGACY_MY_PROJECTS_URL_ACEHARDWARE,
  SYNCHRONY_APPLY_DOMAIN,
  DocumentEnum,
  FinancingParterEnum,
  DepositSetting,
};
